export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyByyNM8I33zzIxhVFBeKTBckXl05WQTE3w',
    authDomain: 'pythone-c7465.firebaseapp.com',
    projectId: 'pythone-c7465',
    storageBucket: 'pythone-c7465.appspot.com',
    messagingSenderId: '17703764684',
    appId: '1:17703764684:web:5258fa5f01954df7e4b869',
    measurementId: 'G-VD13HPYN1X',
  },
};

// export const apis = {
//   baseUrl: 'https://senior-care-api.codegenio.com/api',
//   socketBaseUrl: 'senior-care-api.codegenio.com',
//   socketKey: 'Bh9cxGTTakw7vKN8W1-pxqEJTd6o_ynvT90B',
//   googleApiKey: 'AIzaSyCq-_3Fqmw-4Y6tdKFToydCYdVaprzHTjA',
//   googleCaptchaSiteKey: '',
// };

export const apis = {
  baseUrl: 'https://api.handinhandforsenior.com/api',
  //baseUrl: 'https://senior-care-api.codegenio.com/api',
  socketBaseUrl: 'api.handinhandforsenior.com',
  socketKey: 'Bh9cxGTTakw7vKN8W1-pxqEJTd6o_ynvT90B',
  googleApiKey: 'AIzaSyCq-_3Fqmw-4Y6tdKFToydCYdVaprzHTjA',
  googleCaptchaSiteKey: ''
}

export const socialLoginUrls = {
  google: `${apis.baseUrl}/public/login/google`,
  facebook: `${apis.baseUrl}/public/login/facebook`,
};

// export const appURL = 'https://app.handinhandforsenior.com'

export const appURL = 'https://senior-care.codegenio.com';
