import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http'

@Injectable({
    providedIn: 'root'
})
export class ApiInterceptorsService implements HttpInterceptor {
    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const apiToken = localStorage.getItem('token')

        const customHeaders: any = {
            'ngrok-skip-browser-warning': '1', // Add the ngrok header
            Authorization: apiToken ? `Bearer ${apiToken}` : undefined
        }
        if (apiToken) {
            const clonedReq = req.clone({
                setHeaders: customHeaders
            })
            return next.handle(clonedReq)
        }
        return next.handle(req)
    }
}
