import Quill from 'quill'

const INLINE = Quill.import('blots/inline')

export class CustomAttribute extends INLINE {

    static create(value: any) {
        const node = super.create(value)
        node.setAttribute('contenteditable', 'false')
        // node.setAttribute('width', '300px')
        // Set custom HTML
        node.innerHTML = this.transformValue(value)
        return node
    }

    static transformValue(value: any) {
        let handleArr = value.split('\n')
        handleArr = handleArr.map((e: any) => e.replace(/^[\s]+/, '')
            .replace(/[\s]+$/, ''))
        return handleArr.join('')
    }

    // Returns the value of the node itself for undo operation
    static value(node: any) {
        return node.innerHTML
    }

    public static html(attribute: any): string {
        return `<span class="custom-attribute" data-id="${attribute.id}" contenteditable="false">${attribute.title}</span>`
        return `<span data-id="${attribute.id}" contenteditable="false">
                    <span class="title">${attribute.title}</span>
                    <span class="btn-close"><i class="fa fas-cross"></i></span>
                </span>`
    }
}

